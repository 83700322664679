<template>
  <div>
    <!-- 中文 -->
    <div v-if="$store.state.show">
      <h2>升级新ui</h2>
      <h3>1.ios端</h3>
      <ul>
        <li>
          （1）最新SDK美颜UI与老版本集成方式基本一致，升级新版本需要保证美颜SDK版本在
          <span>3020040000</span> 以上。
        </li>
        <li>
          （2）删除原本工程中<span>TiUI</span>文件夹，重新导入最新的
          <span>TiUI</span>
          文件夹，注意需要将xcode中的工程目录完全删除后再添加。如下图：<br />
          <img src="@/assets/developer/ios3.png" alt="ios端" />
        </li>
      </ul>
      <h3>2.android端</h3>
      <ul>
        <li>
          （1）最新SDK美颜UI与老版本集成方式基本一致，升级新版本需要保证美颜SDK版本在
          <span>3020040000</span> 以上。
        </li>
        <li>
          （2）项目中如果已经集成了<span>tiui模块</span>
          ，直接替换掉即可。如下图：<br />
          <img src="@/assets/developer/android2.png" alt="android端" />
        </li>
        <li>
          （3）如果没有的话，则应替换so文件、aar文件、assets目录、Java文件和res目录下文件。如下图：<br />
          <img src="@/assets/developer/android3.png" alt="android端" />
        </li>
      </ul>
    </div>
    <!-- 英文 -->
    <div v-else>
      <h2>Upgrade New UI</h2>
      <h3>1.iOS</h3>
      <ul>
        <li>
          （1）The integration method of the latest SDK beauty UI is basically
          consistent with that of the old version. To upgrade the new version,
          you need to ensure that the TISDK version is above
          <span>3020040000</span>。
        </li>
        <li>
          （2）Delete the <span>TiUI</span>folder in the original project and
          replace the latest
          <span>TiUI</span>
          folder. Note that the project directory in Xcode should be completely
          deleted before adding. As shown in the figure below:<br />
          <img src="@/assets/developer/ios3.png" alt="ios端" />
        </li>
      </ul>
      <h3>2.android</h3>
      <ul>
        <li>
          （1）Integration method of the latest UI is the same as the earlier
          version’s. To upgrade new UI, it is necessary to ensure your SDK
          version is above
          <span>3020040000</span>。
        </li>
        <li>
          （2）If<span>tiui</span>
          library has been integrated in your project, as shown in the following
          diagram, just replace it all.
          <br />
          <img src="@/assets/developer/android2.png" alt="android端" />
        </li>
        <li>
          （3）If not, as shown in the following diagram, replace
          <span>ti-sdk.aar</span> kit, <span>assets</span> resources,
          <span>jniLibs</span> folder, <span>Java</span> folder and
          <span>res</span> folder.<br />
          <img src="@/assets/developer/android3.png" alt="android端" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #3c4858;
}
h3 {
  font-size: 18px;
  font-weight: 400;
  color: #3c4858;
  margin: 20px 0;
}
li {
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
  width: 715px;
  font-size: 14px;
}
span {
  color: #e91e63;
}
img {
  width: 431px;
  margin: 20px 60px;
}
</style>